import styles from './Button.module.scss'
import React from 'react'
import clsx from 'clsx'
import { bool, node, object, string, oneOf, oneOfType } from 'prop-types'

import withTypeProps from '../__private/withTypeProps'
import withSizeProps from '../__private/withSizeProps'

import LoaderIcon from './../icons/LoaderIcon'

export const NoModifierButton = ({
  as,
  type,
  size,
  icon,
  stretch,
  onClick,
  className,
  disabled,
  loading,
  children,
  ...restProps
}) => {
  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault()
      onClick && onClick(e)
    }
  }

  let Component = as === 'link' ? 'a' : 'button'
  let componentType = as === 'link' ? null : as

  return (
    <Component
      type={componentType}
      onClick={handleClick}
      disabled={disabled}
      className={clsx({
        [styles.root]: true,
        [styles[type]]: type,
        [styles[size]]: size,
        [styles.disabled]: disabled,
        [styles.stretch]: stretch,
        [className]: className,
      })}
      {...restProps}
    >
      <span
        className={clsx({
          [styles.children]: true,
          [styles.loading]: loading,
        })}
      >
        {icon && <span className={clsx({ [styles.icon]: true })}>{icon}</span>}
        {children}
      </span>
      {loading && (
        <div className={styles.loaderWrapper}>
          <LoaderIcon className={styles.loader} />
        </div>
      )}
    </Component>
  )
}

NoModifierButton.displayName = 'Button'

NoModifierButton.defaultProps = {
  disabled: false,
  loading: false,
  as: 'button',
  type: 'normal',
  size: 'regular',
}

NoModifierButton.propTypes = {
  /**
   * Additional className for button component
   */
  className: oneOfType([string, object]),
  /**
   * String indicating wether the button should render as button, submit button, or link.
   */
  as: oneOf(['button', 'submit', 'link']),
  /** Boolean indicating whether the button should render as disabled */
  disabled: bool,
  loading: bool,
  /**
   * Type of the button
   * You can use it directly as a prop
   */
  type: string,
  /**
   * Size of button
   * You can use it directly as a prop
   */
  size: string,
  children: node.isRequired,
}

const Button = withSizeProps(withTypeProps(NoModifierButton))

export default Button
