import styles from './Brand.module.scss'
import cx from 'clsx'

import LogoFull from './logo/BrandFull'
import LogoWordmark from './logo/LogoWordmark'

const BrandWrapper = ({ children, className, ...restProps }) => {
  return (
    <div
      className={cx({
        [styles.root]: true,
        [className]: className,
      })}
      {...restProps}
    >
      <div className={styles.brandContainer}>{children}</div>
    </div>
  )
}

const NavbarBrand = ({ type, children, className, ...restProps }) => {
  return <BrandWrapper className={styles.className}>{children}</BrandWrapper>
}

export const BrandFull = ({ className, white, ...restProps }) => {
  return (
    <BrandWrapper>
      <LogoFull white={white} className={cx(styles.brand, className)} />
    </BrandWrapper>
  )
}

export const BrandNavbar = ({ className, white, ...restProps }) => {
  return (
    <BrandWrapper className={styles.wrapperNavbar}>
      <LogoFull
        white={white}
        className={cx(styles.brand, className)}
        {...restProps}
      />
    </BrandWrapper>
  )
}

export const BrandCustom = ({ className, ...restProps }) => {
  return (
    <LogoFull className={cx(styles.brandCustom, className)} {...restProps} />
  )
}

export const BrandStack = ({ className, ...restProps }) => {
  return (
    <BrandWrapper>
      <LogoWordmark className={cx(styles.brandWordmark, className)} />
    </BrandWrapper>
  )
}

export default NavbarBrand
