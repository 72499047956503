import styles from './PasswordField.module.scss'
import React, { useEffect, useState } from 'react'
import cx from 'clsx'

import ShowIcon from './ShowIcon'
import HideIcon from './HideIcon'
import CheckCircleIcon from './CheckCircleIcon'

import Link from '../Link/Link'

const valueHasNumber = /\d/
// const valueHasLetter = /[A-Za-z]/
// const valueHasSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

const PasswordField = ({
  setHasError,
  forgotPassword,
  validate,
  id,
  name,
  label,
  secondaryLabel,
  className,
  value,
  hint,
  setValue,
  ...restProps
}) => {
  const [show, setShow] = useState(false)

  const [min, setMin] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [hasLetter, setHasLetter] = useState(false)
  const [hasSpecial, setHasSpecial] = useState(false)

  const [errorMin, setErrorMin] = useState(false)
  const [errorHasNumber, setErrorHasNumber] = useState(false)
  const [errorHasLetter, setErrorHasLetter] = useState(false)
  const [errorHasSpecial, setErrorHasSpecial] = useState(false)

  useEffect(() => {
    if (errorMin || errorHasLetter || errorHasNumber || errorHasSpecial) {
      setHasError(true)
    } else {
      setHasError(false)
    }
  }, [errorMin, errorHasNumber, errorHasLetter, errorHasSpecial])

  const handleValueTest = (value) => {
    setMin(value.length >= 8)
    setHasNumber(valueHasNumber.test(value))
  }

  const handleFocus = () => {
    setErrorMin(false)
    setErrorHasNumber(false)
  }

  const handleBlur = () => {
    // console.log({ min, hasNumber, hasLetter, hasSpecial })
    setErrorMin(!min)
    setErrorHasNumber(!hasNumber)
  }

  return (
    <div className={cx(styles.root)} {...restProps}>
      <label htmlFor={id} className={styles.label}>
        <span className={styles.primaryLabel}>
          {label}{' '}
          {secondaryLabel && (
            <span className={styles.secondaryLabel}>{secondaryLabel}</span>
          )}
        </span>
        {forgotPassword && (
          <Link to="/lupa-password" className={styles.forgot}>
            Lupa password?
          </Link>
        )}
      </label>
      <div className={styles.fieldWrapper}>
        <input
          className={cx(styles.field, {
            [styles.error]:
              errorMin || errorHasNumber || errorHasLetter || errorHasSpecial,
          })}
          type={show ? 'text' : 'password'}
          id={id}
          name={name || id}
          value={value}
          onFocus={validate && handleFocus}
          onBlur={validate && handleBlur}
          onChange={(e) => {
            validate && handleValueTest(e.currentTarget.value)
            setValue(e)
          }}
        />
        <button
          type="button"
          className={styles.visibilityWrapper}
          onClick={() => setShow(!show)}
        >
          {show ? (
            <HideIcon className={styles.icon} />
          ) : (
            <ShowIcon className={styles.icon} />
          )}
        </button>
      </div>
      {hint && (
        <ul className={styles.hintWrapper}>
          <li
            className={cx(styles.hint, {
              [styles.error]: errorMin,
              [styles.success]: min,
            })}
          >
            <CheckCircleIcon className={styles.hintIcon} />
            <span className={styles.hintLabel}>Minimal 8 karakter</span>
          </li>
          <li
            className={cx(styles.hint, {
              [styles.error]: errorHasNumber,
              [styles.success]: hasNumber,
            })}
          >
            <CheckCircleIcon className={styles.hintIcon} />
            <span className={styles.hintLabel}>Minimal 1 angka</span>
          </li>
        </ul>
      )}
    </div>
  )
}

export default PasswordField
