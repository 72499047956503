import React from 'react'

const ShowIcon = ({ style, className, ...restProps }) => {
  return (
    <svg
      className={className}
      style={{ display: 'block', ...style }}
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <g>
        <rect fill="none" height="24" width="24" y="0" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M12,4C7.31,4,3.26,6.74,1.35,10.7c-0.24,0.5-0.24,1.1,0,1.6C3.26,16.26,7.31,19,12,19s8.74-2.74,10.65-6.7 c0.24-0.5,0.24-1.1,0-1.6C20.74,6.74,16.69,4,12,4z M12,16c-2.48,0-4.5-2.02-4.5-4.5S9.52,7,12,7s4.5,2.02,4.5,4.5S14.48,16,12,16 z"
          />
          <circle fill="currentColor" cx="12" cy="11.5" r="2.7" />
        </g>
      </g>
    </svg>
  )
}

export default ShowIcon
