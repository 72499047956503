import React from "react"
import NextLink from "next/link"

const Link = ({ children, onClick, external, as, to, ...restPprops }) =>
  external ? (
    <a href={to} {...restPprops} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <NextLink href={to} as={as}>
      <a {...restPprops} rel="noopener noreferrer">
        {children}
      </a>
    </NextLink>
  )

export default Link
