import React from 'react'

const HideIcon = ({ style, className, ...restProps }) => {
  return (
    <svg
      className={className}
      style={{ display: 'block', ...style }}
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <g>
        <rect fill="none" height="24" width="24" y="0" />
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M22.65,10.7C20.74,6.74,16.69,4,12,4c-1.53,0-3,0.3-4.34,0.83l2.55,2.55C10.76,7.14,11.36,7,12,7c2.48,0,4.5,2.02,4.5,4.5 c0,0.64-0.14,1.24-0.38,1.79l3.17,3.17c1.41-1.11,2.57-2.53,3.35-4.16C22.89,11.8,22.89,11.2,22.65,10.7z"
          />
          <path
            fill="currentColor"
            d="M12,8.8c-0.12,0-0.23,0.02-0.34,0.03l3,3c0.01-0.11,0.03-0.22,0.03-0.34C14.7,10.01,13.49,8.8,12,8.8z"
          />
          <path
            fill="currentColor"
            d="M3.51,3.51c-0.39-0.39-1.02-0.39-1.41,0v0c-0.39,0.39-0.39,1.02,0,1.41l2.06,2.06C3,8.02,2.04,9.28,1.35,10.7 c-0.24,0.5-0.24,1.1,0,1.6C3.26,16.26,7.31,19,12,19c1.26,0,2.47-0.2,3.6-0.57l3.47,3.47c0.39,0.39,1.02,0.39,1.41,0 c0.39-0.39,0.39-1.02,0-1.41L3.51,3.51z M12,16c-2.48,0-4.5-2.02-4.5-4.5c0-0.36,0.05-0.7,0.13-1.04l1.78,1.78 c0.26,0.89,0.95,1.58,1.84,1.84l1.78,1.78C12.7,15.95,12.36,16,12,16z"
          />
        </g>
      </g>
    </svg>
  )
}

export default HideIcon
